<template>
    <div class="stage-inner-container-padded" :key="formKey">
        <b-row>
            <b-col cols="12">
                <h2>{{(isReplacement) ? 'Enter BFPA Replacement' : 'Install New Device'}}</h2>
            </b-col>
        </b-row>
        <b-form @submit="validateForm">
            <!-- Connection Info Block -->
             <b-card v-if="isReplacement && device.fetchedModel" header="On Connection" header-class="larger-header" >
                <b-card-body>
                    <!-- Device Info Block -->
                    <b-row no-gutters>
                        <b-col sm="3" md="3" lg="2" xl="2">
                            <!-- Empty Spacer -->
                        </b-col>
                        <b-col sm="8" md="8" lg="9" xl="9">
                            <b-row no-gutters>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Meter Number:</b> {{connection.meter_number}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Meter Size:</b> {{connection.meter_size}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Service Type:</b> {{connection.service_type}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Route:</b> {{connection.route}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Location ID:</b> {{connection.location_id}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Utility:</b> {{connection.utility}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Utility Act#:</b> {{connection.utility_account_number}} 
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Device Info - Device Being Replaced -->
            <b-card v-if="isReplacement && device.fetchedModel" header="Device That Was Replaced" header-class="larger-header" >
                <b-card-body>
                    <!-- Device Info Block -->
                    <b-row no-gutters>
                        <b-col sm="3" md="3" lg="2" xl="2">
                            <!-- Empty Spacer -->
                        </b-col>
                        <b-col sm="8" md="8" lg="9" xl="9">
                            <b-row no-gutters>
                                <b-col :class="(device.modelFlagged) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Model:</b> {{device.manufacturer + ' ' + device.type + ' ' + device.model}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Serial:</b> {{device.serial}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Size:</b> {{device.size}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Location:</b> {{device.location}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Hazard Level:</b> {{device.hazardLevel}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Protection Type:</b> {{device.protectionType}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Assembly ID:</b> {{device.assemblyID}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Special Flag:</b> {{device.specialFlag}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="6" xl="6" class="text-left">
                                    <b>Hazard Type:</b> {{device.hazardType}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="12" xl="12" class="text-left">
                                    <b>Notes:</b> {{device.notes}} 
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-card v-else-if="!isReplacement && connection.fetched" header="Installing New Device At Connection" header-class="larger-header" >
                <b-card-body>
                    <!-- Device Info Block -->
                    <b-row no-gutters>
                        <b-col sm="3" md="3" lg="2" xl="2">
                            <!-- Empty Spacer -->
                        </b-col>
                        <b-col sm="8" md="8" lg="9" xl="9">
                            <b-row no-gutters>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Meter Number:</b> {{connection.meter_number}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Meter Size:</b> {{connection.meter_size}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Service Type:</b> {{connection.service_type}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Route:</b> {{connection.route}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Location ID:</b> {{connection.location_id}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Utility:</b> {{connection.utility}} 
                                </b-col>
                                <b-col sm="12" md="12" lg="4" xl="3" class="text-left">
                                    <b>Utility Act#:</b> {{connection.utility_account_number}} 
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Technician Selection -->
            <b-card v-if="!formHasError" header="Select Technician" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <!-- Technician Selection -->
                            <api-select
                                :dataName="'technician_id'"
                                :inputLabel="'Technician'"
                                :apiEndpoint="'/technicians/choices.json?order=test_count'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="techChanged" 
                            ></api-select>
                        </b-col>
                    </b-row>
                    <!-- Technician Info Block -->
                    <b-row no-gutters v-if="technician.selected">
                        <b-col sm="4" md="4" lg="3" xl="3">
                            <!-- Empty Spacer -->
                        </b-col>
                        <b-col sm="8" md="8" lg="9" xl="9">
                            <b-row no-gutters>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Technician:</b> {{technician.first + ' ' + technician.last}} 
                                </b-col>
                                <b-col sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Company:</b> {{technician.company}} 
                                </b-col>
                                <b-col v-if="technician.email[0] != null" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>e-mail:</b> {{technician.email[0]}} 
                                </b-col>
                                <b-col v-if="technician.phone[0] != null" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Phone:</b> {{technician.phone[0]}} 
                                </b-col>
                                <b-col :class="(technician.certs.backflow_tester.num == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Cert #:</b> {{technician.certs.backflow_tester.num}} 
                                </b-col>
                                <b-col :class="(technician.certs.backflow_tester.date == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Cert Date:</b> {{technician.certs.backflow_tester.date}} 
                                </b-col>
                                <b-col :class="(technician.testKit.model == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Test Kit:</b> {{testKitModel}} 
                                </b-col>
                                <b-col :class="(technician.testKit.serial == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Test Kit Serial:</b> {{technician.testKit.serial}} 
                                </b-col>
                                <b-col :class="(testKitCalDate == null) ? 'bg-warning' : ''" sm="12" md="6" lg="4" xl="3" class="text-left">
                                    <b>Test Kit Calibrated:</b> {{testKitCalDate}} 
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Event Date and Time Selection -->
            <b-card v-if="!formHasError" header="Event Date & Time" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col sm="12" md="6">
                            <!-- Date Selection -->
                            <date-input
                                :dataName="'event_date'"
                                :inputLabel="'Event Date'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></date-input>
                        </b-col>
                        <b-col sm="12" md="6">
                            <!-- Time Selection -->
                            <time-input
                                :dataName="'event_time'"
                                :inputLabel="'Event Time'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></time-input>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Pre-Work Block -->
            <b-card v-if="connection.fetched && !formHasError && technician.selected && eventTStampCompleted" :header="(isReplacement) ? 'Pre-Replacement Info': 'Pre-Install Info'" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col sm="12" md="6" lg="4">
                            <!-- Meter Reading Applicable -->
                            <bool-select
                                :dataName="'meterReadingApplicable'"
                                :inputLabel="'Meter Reading Applicable'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Has Meter', value:true},{text: 'No Meter', value:false}]"
                                :size="'lg'"
                                :value="(connection.meter_number == 'NO METER' || connection.meter_number == 'Na') ? false : repValues.meterReadingApplicable"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4" v-if="repValues.meterReadingApplicable">
                            <!-- Meter Reading -->
                            <integer-input
                                :dataName="'meterReading'"
                                :inputLabel="'Meter Reading'"
                                :required="true"
                                :value="repValues.meterReading"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></integer-input>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <!-- Curbstop Condition -->
                            <bool-select
                                :dataName="'curbstopCondition'"
                                :inputLabel="'Curbstop Condition'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Closes', value:true},{text: 'Did Not Close', value:false}]"
                                :size="'lg'"
                                :value="repValues.curbstopCondition"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <!-- Replacement Block -->
            <b-card v-if="repInstPreworkCompleted && !formHasError && technician.selected && eventTStampCompleted" 
            :header="(isReplacement) ? 'Device That Replaces: ' + device.manufacturer + ' ' + device.type + ' ' + device.model + ' ' + device.serial: 'Installed'" 
            header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <!-- Device Model -->
                            <gps-input
                                :dataName="'gps'"
                                :latitudeLabel="'New Device Latitude'"
                                :longitudeLabel="'New Device Longitude'"
                                :latitude="device.gps.lat"
                                :longitude="device.gps.lng"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></gps-input>
                        </b-col>
                        <b-col cols="12">
                            <!-- Device Model -->
                            <api-select
                                :dataName="'modelID'"
                                :inputLabel="'Model'"
                                :apiEndpoint="'/device_models/model_choices.json?choices=true'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></api-select>
                        </b-col>
                        <b-col md="12" lg="6" >
                            <!-- Device Size -->
                            <decimal-2x2-input
                                :dataName="'size'"
                                :inputLabel="'Size'"
                                :required="true"
                                :parentFormID="localFormID"
                                :value="(device.fetchedDevice) ? device.size : connection.meter_size"
                                @onTouch="fieldTouched"
                            ></decimal-2x2-input>
                        </b-col>
                        <b-col md="12" lg="6" v-if="newDeviceRequiresSerial">
                            <!-- Device Serial -->
                            <text-input
                                :dataName="'serial'"
                                :inputLabel="'Serial'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></text-input>
                        </b-col>
                        <b-col cols="12">
                            <!-- Device Location -->
                            <text-input
                                :dataName="'location'"
                                :inputLabel="'Location'"
                                :required="true"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></text-input>
                        </b-col>
                        <b-col md="12" lg="6">
                            <!-- Hazard Type -->
                            <api-select
                                :dataName="'hazardTypeID'"
                                :inputLabel="'Hazard Type'"
                                :apiEndpoint="'/choices/hazard_types.json?choices=true'"
                                :required="false"
                                :value="(device.fetchedDevice) ? device.hazardTypeID : null"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></api-select>
                        </b-col>
                        <b-col md="12" lg="6">
                            <!-- Hazard Type -->
                            <api-select
                                :dataName="'hazardLevelID'"
                                :inputLabel="'Hazard Level'"
                                :apiEndpoint="'/choices/hazard_levels.json?choices=true'"
                                :required="false"
                                :value="(device.fetchedDevice) ? device.hazardLevelID : null"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></api-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <!-- Protection Type -->
                            <api-select
                                :dataName="'protectionTypeID'"
                                :inputLabel="'Protection Type'"
                                :apiEndpoint="'/choices/protection_types.json?choices=true'"
                                :required="false"
                                :value="(device.fetchedDevice) ? device.protectionTypeID : null"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></api-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <!-- Grade Type -->
                            <api-select
                                :dataName="'gradeTypeID'"
                                :inputLabel="'Grade Types'"
                                :apiEndpoint="'/choices/grade_types.json?choices=true'"
                                :required="false"
                                :value="(device.fetchedDevice) ? device.gradeTypeID : null"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched" 
                            ></api-select>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <!-- Device Elevation -->
                            <bool-select
                                :dataName="'deviceElevation'"
                                :inputLabel="'Device Elevation'"
                                :required="true"
                                :parentFormID="localFormID"
                                :options="[{text: 'Above Grade', value:'1'},{text: 'Below Grade', value:'0'}]"
                                :size="'lg'"
                                :value="repValues.heightAboveGrade"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" md="6">
                            <!-- Device Elevation -->
                            <passed-select 
                                :dataName="'scheduleCode'"
                                :inputLabel="'Schedule Code'"
                                :required="true"
                                :value="device.scheduleCode"
                                :options="scheduleCodeChoices"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></passed-select>
                        </b-col>
                        <b-col sm="12" md="6">
                            <!-- Device Elevation -->
                            <text-input
                                :dataName="'specialFlag'"
                                :inputLabel="'Special Flag'"
                                :required="false"
                                :value="device.specialFlag"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></text-input>
                        </b-col>
                        <b-col cols="12">
                            <!-- Device Notes -->
                            <text-area
                                :dataName="'notes'"
                                :inputLabel="'Device Notes'"
                                :required="false"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></text-area>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            <b-card v-if="repInstPreworkCompleted && repInstDeviceCompleted && !formHasError && technician.selected && eventTStampCompleted" :header="(isReplacement) ? 'Replacement Notes': 'Install Notes'" header-class="larger-header" >
                <b-card-body>
                    <b-row>
                        <b-col sm="12" lg="6">
                            <!-- Verified Pressure -->
                            <bool-select
                                :dataName="'verifiedPressure'"
                                :inputLabel="'Verified Pressure'"
                                :required="true"
                                :parentFormID="localFormID"
                                :threeState="true"
                                :options="[{text: 'Yes', value:true},{text: 'No', value:false},{text: 'N/A', value:null}]"
                                :size="'lg'"
                                :value="repValues.verifiedPressure"
                                @onTouch="fieldTouched"
                            ></bool-select>
                        </b-col>
                        <b-col sm="12" lg="6">
                            <!-- Replacement Notes -->
                            <passed-select 
                                :dataName="'outletConnectionInfo'"
                                :inputLabel="'Outlet Connection Info'"
                                :options="outletConInfoChoices"
                                :multiSelect="true"
                                :required="true"
                                :parentFormID="localFormID"
                                :displayCount="9"
                                :value="[repValues.outletConnectionInfo]"
                                @onTouch="fieldTouched"
                            ></passed-select>
                        </b-col>
                        <b-col cols="12">
                            <!-- Device Notes -->
                            <text-area
                                :dataName="'repNotes'"
                                :inputLabel="'Replacement Notes'"
                                :required="false"
                                :parentFormID="localFormID"
                                @onTouch="fieldTouched"
                            ></text-area>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-row class="mt-2">
                <!-- Spacer -->
            </b-row>
            
            <b-button-toolbar justify v-if="!formHasError">
                <b-button v-if="!submittedSuccesfully" variant="warning" @click="$router.go(-1)">Discard Replacement and Exit</b-button>
                <b-button v-else variant="info"  @click="$router.go(-1)">Exit</b-button>
                <b-overlay :show="isSubmitting" rounded="sm">
                    <b-button v-if="!submittedSuccesfully" variant="success" @click="formValidation()">Validate & Submit</b-button>
                    <b-button v-else variant="success" disabled>Submitted</b-button>
                </b-overlay>
            </b-button-toolbar>
        </b-form>
        <b-modal 
            id="validation-failure-modal"
            size="xl"
            centered
            :title="(isReplacement) ? 'Unable To Submit Replacement' : 'Unable To Submit Test'"
            header-bg-variant="danger"
            header-text-variant="light"
            body-bg-variant="light"
            body-text-variant="dark"
        >
            <b-container fluid>
                <b-row>
                    <b-col cols="12">
                        <b>This {{(isReplacement) ? 'replacement' : 'installation'}} cannot be submitted in it's current state, please fix the following problems before submitting:</b>
                    </b-col>
                </b-row>
                <hr>
                <b-row v-for="(vf, index) in validationFailures" :key="index">
                    <b-col cols="12">
                        {{vf}}
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <hr>
        <b-modal
            id="submission-completed"
            size="lg"
            centered
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hide-header-close
            header-text-variant="success"
            title="Replacement Submitted Successfully"
        >
            <h5 v-if="newDeviceIsTestable" class="text-center">
                It Is Highly Recommended That A Test Is Entered For This New Device
            </h5>
            <div class="mt-3"></div>
            <b-button variant="success" @click="enterTestForNewDevice()" block v-if="!formHasError && newDeviceIsTestable">Alright, Lets Enter A Test</b-button>
            <div class="mt-5"></div>
            <b-button-toolbar justify v-if="!formHasError && newDeviceIsTestable">
                <b-button variant="warning" @click="$router.go(-1)">No Thanks, Just Exit</b-button>
                <b-button variant="warning" @click="goToNewDevice()">No Thanks, View New Device</b-button>
            </b-button-toolbar>
            <b-button-toolbar justify v-else>
                <b-button variant="info" @click="$router.go(-1)">Exit</b-button>
                <b-button variant="info" @click="goToNewDevice()">View New Device</b-button>
            </b-button-toolbar>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

// Form Components
import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import DateInput from '@/components/FormDateInput.vue'
import TimeInput from '@/components/FormTimeInput.vue'

export default {
    name: 'bfpareplacemententry',
    components: {
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'date-input': DateInput,
        'time-input': TimeInput
    },
    props:{
        actionType: String,
        inspectID:{
            type: String,
            default: () => { return null; }
        },
        localFormID: {
            type: String,
            default: () =>{ return uuidv4(); }
        },
        titleElements: {
            type: Array,
            default: () => { return []; }
        },
        showTitle: {
            type: Boolean,
            default: () => { return true; }
        },
        showBGColor: {
            type: Boolean,
            default: () => { return false; }
        },
        deviceInfo: {
            type: Object,
            default: () => { return null; }
        }
    },
    data(){
        return{
            formKey: 1,
            id: null,
            formHasError: false,
            inspect: null,
            anyDirty: false,
            enableQRNOther: false,
            validationFailures: [],
            eventDate: null,
            eventTime: null,
            eventTStampCompleted: false,
            isSubmitting: false,
            submittedSuccesfully: false,
            isReplacement: false,
            newDeviceRequiresSerial: true,
            newDeviceID: null,
            ownEventID: null,
            connection: {
                fetched: false,
                location_id: null,
                utility_account_number: null,
                utility: null,
                meter_number: null,
                route: null,
                service_type: null,
                meter_size: null,
                amr_number: null
            },
            nonTestableDeviceTypes: ['Dual Check', 'NONE', 'UNKNOWN', 'Single Check', 'SDC'],
            technician: {
                selected: false,
                id: null,
                first: null,
                last: null,
                company: null,
                email: [null],
                phone: [null],
                certifications: {
                    treeo: {
                        num: null,
                        date: null
                    }
                },
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
                testKit: {
                    manufacturer: null,
                    model: null,
                    serial: null,
                    calDate: null
                }
            },
            device: {
                id: null,
                connectionID: null,
                fetchedDevice: false,
                fetchedModel: false,
                modelFlagged: false,
                disabled: false,
                inactive: null,
                modelID: null,
                manufacturer: null,
                type: null,
                model: null,
                serial: null,
                size: null,
                gps:{
                    lat: null,
                    lng: null,
                    acc: null
                },
                location: null,
                notes: null,
                hazardType: null,
                hazardTypeID: null,
                hazardLevel: null,
                hazardLevelID: null,
                protectionType: null,
                protectionTypeID: null,
                gradeType: null,
                gradeTypeID: null,
                specialFlag: null,
                scheduleCode: null,
                connectionID: null,
                condition: [null],
                conditionNotes: null,
                assemblyID: null
            },
            repValues: {
                meterReadingApplicable: true,
                meterReading: null,
                curbstopCondition: null,
                modelID: null,
                manufacturer: null,
                type: null,
                model: null,
                serial: null,
                size: null,
                gps:{
                    lat: null,
                    lng: null,
                    acc: null,
                    timestamp: null
                },
                specialFlag: null,
                location: null,
                notes: null,
                hazardTypeID: null,
                hazardLevelID: null,
                protectionTypeID: null,
                gradeTypeID: null,
                scheduleCode: null,
                deviceElevation: null,
                connectionID: null,
                outletConnectionInfo: null,
                repNotes: null,
                verifiedPressure: null
            },
            repInstPreworkCompleted: false,
            repInstPreworkFields: ['meterReadingApplicable', 'meterReading', 'curbstopCondition'],
            repInstDeviceCompleted: false,
            repInstDeviceFields: ['modelID','size','serial','location','hazardTypeID','hazardLevelID','protectionTypeID','gradeTypeID','deviceElevation',],
            repInstNotesCompleted: false,
            repInstNotesFields: ['verifiedPressure','outletConnectionInfo','repNotes'],
            formDef:{
                meta: {
                    name: 'Replace or Install Device',
                    title: 'Replace or Install Device',
                    formParentKey: null
                },
                fields: {}
            },
            outletConInfoChoices: [
                {value: 'PVC', text: 'PVC'},
                {value: 'Copper', text: 'Copper'},
                {value: 'Steel', text: 'Steel'},
                {value: 'PEX', text: 'PEX'},
                {value: 'On re-setter', text: 'On re-setter'},
                {value: 'Reused existing adapter', text: 'Reused existing adapter'},
                {value: 'Glue work done', text: 'Glue work done'},
                {value: 'Compression fitting used', text: 'Compression fitting used'},
            ],
            scheduleCodeChoices: [
                {value: '05-May', text:'05-May'},
                {value: '06-Jun', text:'06-Jun'},
                {value: '04-Apr', text:'04-Apr'},
                {value: '08-Aug', text:'08-Aug'},
                {value: '09-Sep', text:'09-Sep'},
                {value: '11-Nov', text:'11-Nov'},
                {value: '07-Jul', text:'07-Jul'},
                {value: '03-Mar', text:'03-Mar'},
                {value: '10-Oct', text:'10-Oct'},
                {value: '12-Dec', text:'12-Dec'},
                {value: '02-Feb', text:'02-Feb'},
                {value: '01-Jan', text:'01-Jan'},
                {value: '02-Feb ODD', text:'02-Feb ODD'},
                {value: '12-Dec ODD', text:'12-Dec ODD'},
                {value: '07-Jul ODD', text:'07-Jul ODD'},
                {value: '12-Dec EVN', text:'12-Dec EVN'},
                {value: '04-Apr ODD', text:'04-Apr ODD'},
                {value: '05-May EVN', text:'05-May EVN'},
                {value: '10-Oct EVN', text:'10-Oct EVN'},
                {value: '04-Apr EVN', text:'04-Apr EVN'},
                {value: '08-Aug EVN', text:'08-Aug EVN'},
                {value: '10-Oct ODD', text:'10-Oct ODD'},
                {value: '06-Jun EVN', text:'06-Jun EVN'},
                {value: '05-May ODD', text:'05-May ODD'},
                {value: '01-Jan EVN', text:'01-Jan EVN'},
                {value: '01-Jan ODD', text:'01-Jan ODD'},
                {value: '03-Mar ODD', text:'03-Mar ODD'},
                {value: '08-Aug ODD', text:'08-Aug ODD'},
                {value: '02-Feb EVN', text:'02-Feb EVN'},
                {value: '11-Nov EVN', text:'11-Nov EVN'},
                {value: '03-Mar EVN', text:'03-Mar EVN'},
                {value: '07-Jul EVN', text:'07-Jul EVN'},
                {value: '06-Jun ODD', text:'06-Jun ODD'},
                {value: '09-Sep ODD', text:'09-Sep ODD'},
                {value: '09-Sep EVN', text:'09-Sep EVN'},
                {value: '11-Nov ODD', text:'11-Nov ODD'}
            ],
            formTitle: "Loading...",
        }
    },
    methods: {
        goToNewDevice(){
            var thisFormType = 'devices';
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + this.newDeviceID })
            }
        },
        enterTestForNewDevice(){
            this.$router.push({path: '/home/add/test/' + this.newDeviceID})
        },
        newDeviceIsTestable(){
            if(!this.nonTestableDeviceTypes.includes(this.repValues.model)){
                return true;
            }else{
                return false;
            }
        },
        fieldTouched(dataName){
            if(dataName == 'gps'){
                var latVal = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName + "_latitude"});
                var lngVal = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName + "_longitude"});
                this.repValues.gps.lat = latVal;
                this.repValues.gps.lng = lngVal;
                this.repValues.gps.acc = 10;
                var date = new Date();
                this.repValues.gps.timestamp = date;
            }else{
                var fieldValue = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName});
                if(this.repInstDeviceFields.includes(dataName)){
                    // New Device
                    if(dataName == 'modelID'){
                        // Fetch Model
                        this.fetchModelInformation(fieldValue, true);
                        this.repValues[dataName] = fieldValue;
                    }else{
                        this.repValues[dataName] = fieldValue;
                    }
                    this.repInstDeviceCompleted = this.newDevicevalid();
                }else if(this.repInstPreworkFields.includes(dataName)){
                    // Prework
                    this.repValues[dataName] = fieldValue;
                    this.repInstPreworkCompleted = this.preworkValid();
                }else if(this.repInstNotesFields.includes(dataName)){
                    // Notes
                    this.repValues[dataName] = fieldValue;
                    this.repInstNotesCompleted = this.notesvalid();
                }else if(dataName == 'event_date'){
                    this.eventDate = fieldValue;
                    if(this.eventDate != null && this.eventTime != null){
                        this.eventTStampCompleted = true;
                    }
                }else if(dataName == 'event_time'){
                    this.eventTime = fieldValue;
                    if(this.eventDate != null && this.eventTime != null){
                        this.eventTStampCompleted = true;
                    }
                }else{
                    this.repValues[dataName] = fieldValue;
                }
            }
            
        },
        techChanged(dataName){
            // Called Exclusively From The Technician Field
            var fieldValue = this.$store.getters.getFormValue({parentFormID: this.localFormID, dataName: dataName});
            // Clear the technician value
            this.technician = {
                selected: false,
                id: null,
                first: null,
                last: null,
                company: null,
                email: [null],
                phone: [null],
                certifications: {
                    treeo: {
                        num: null,
                        date: null
                    }
                },
                certs: {
                    backflow_tester: {
                        num: null,
                        date: null
                    }
                },
                testKit: {
                    manufacturer: null,
                    model: null,
                    serial: null,
                    calDate: null
                }
            };
            this.technician.id = fieldValue;
            instance.get(process.env.VUE_APP_API_BASE_URL + '/technicians/' + fieldValue + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var tech = response.data.result.records[0];
                        // Update Techncician Information In Model
                        this.technician.selected = true;
                        this.technician.first = tech.tech_first_name;
                        this.technician.last = tech.tech_last_name;
                        this.technician.company = tech.tech_company;
                        if(tech.tech_email != null){
                            this.technician.email = tech.tech_email;
                        }else{
                            this.technician.email = [null]
                        }
                        if(tech.tech_phone != null){
                            this.technician.phone = tech.tech_phone;
                        }else{
                            this.technician.phone = [null]
                        }
                        if(_.has(tech.certifications, 'treeo')){
                            this.technician.certifications.treeo.num = tech.certifications.treeo.num;
                            this.technician.certifications.treeo.date = tech.certifications.treeo.date;
                        }else{
                            this.technician.certifications = {treeo: {num: null,date: null}};
                        }

                        if(_.has(tech.certs, 'backflow_tester')) {
                            this.technician.certs.backflow_tester.num = tech.certs.backflow_tester.num;
                            this.technician.certs.backflow_tester.date = tech.certs.backflow_tester.date;
                        } else {
                            this.technician.certs.backflow_tester = {num: null, date: null};
                        }

                        this.technician.testKit.manufacturer = tech.test_kit_manufacturer;
                        this.technician.testKit.model = tech.test_kit_model;
                        this.technician.testKit.serial = tech.test_kit_serial;
                        this.technician.testKit.calDate = tech.test_kit_cal_date;
                        // Generate Tech Full Name
                        var techName = this.technician.first + ' ' + this.technician.last;
     
                        // Update Technician Fields
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'tech_name', value: techName, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_number', value: this.technician.certs.backflow_tester.num, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_date', value: this.technician.certs.backflow_tester.date, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_kit_sn', value: this.technician.testKit.serial, valid: true, isDirty: true} );
                        this.$store.commit('updateFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_klt_cal_date', value: this.technician.testKit.calDate, valid: true, isDirty: true} );
                    }else{
                        this.makeToast("Failed To Fetch Technician Info", "Technician Information Could Not Be Retrieved Or Was Not Retrieved", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Technician Info", "Technician Information Could Not Be Retrieved Or Was Not Retrieved", 'danger');
                    console.log(error);
                });
        },
        formValidation(){
            var failureMessages = [];
            if(this.formHasError){
                // Form Has Error Which Prevents Test Adding
                failureMessages.push("The Technician Selected Is Invalid, or The Device/Connection Selected For Replacement/Install Does Not Exist")
                this.validationFailures = failureMessages;
                this.$bvModal.show('validation-failure-modal')
            }
            if(!this.eventTStampCompleted){
                // Form Has Error Which Prevents Test Adding
                failureMessages.push("Event Date and Time Are Required")
                this.validationFailures = failureMessages;
                this.$bvModal.show('validation-failure-modal')
            }
            if(this.technician.selected && this.connection.fetched ){
                var pwValid = this.preworkValid();
                var newDevValid = this.newDevicevalid();
                var notesValid = this.notesvalid();
                if(pwValid && newDevValid && notesValid){
                    // Is Okay, Test is Valid
                    this.packageRepInstallAndSubmit()
                }else{
                    var form = this.$store.getters.getForm(this.localFormID);
                    var keys = _.keys(form);
                    var invalidFieldNames = _.filter(keys, (k)=>{
                        return form[k].valid == false;
                    });
                    invalidFieldNames.forEach((fn)=>{
                        failureMessages.push(`${fn} is invalid`);
                    })
                    this.validationFailures = failureMessages;
                    this.$bvModal.show('validation-failure-modal')
                }
            }else{
                // Either Technician Or Device Is Invalid
                if(this.technician.id == null){
                    failureMessages.push("Technician is required");
                } 
                if(this.connection.fetched == false){
                    failureMessages.push("Connection Specified Does Not Exist Or Is Invalid");
                }
                this.validationFailures = failureMessages;
                this.$bvModal.show('validation-failure-modal')
            }
        },
        validateForm(evt){
            evt.preventDefault()
        },
        fetchValues(evt){
            evt.preventDefault()
        },
        fetchDeviceInformation(devID, performConnectionUpdate){
            if(devID == null){
                makeToast("Unable To Enter BFPA Test, Missing or Malformed Device ID", "Device ID is Null, Check The URL or Link and Attempt Again", 'danger');
                return;
            }
            instance.get(process.env.VUE_APP_API_BASE_URL + '/devices/' + devID + '.json?dref=true')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        this.device.fetchedDevice = true;
                        var dev = response.data.result.records[0];
                        this.device.id = dev.device_id;
                        this.device.connectionID = dev.connection_id;
                        this.device.disabled = dev.disabled;
                        this.device.inactive = dev.inactive;
                        this.device.modelID = dev.device_model_id;
                        this.device.serial = dev.device_serial;
                        this.device.size = dev.device_size;
                        this.device.gps = {
                            lat: dev.device_latitude,
                            lng: dev.device_longitude,
                            acc: dev.device_gps_accuracy
                        };
                        this.device.location = dev.device_location;
                        this.device.notes = dev.device_comments;
                        this.device.hazardType = dev.hazard_type;
                        this.device.hazardTypeID = dev.hazard_type_id;
                        this.device.hazardLevel = dev.hazard_level;
                        this.device.hazardLevelID = dev.hazard_level_id;
                        this.device.protectionType = dev.protection_type;
                        this.device.protectionTypeID = dev.protection_type_id;
                        this.device.gradeType = dev.grade_type;
                        this.device.gradeTypeID = dev.grade_type_id;
                        this.device.specialFlag = dev.special_flag;
                        this.device.scheduleCode = dev.schedule_code;
                        this.device.condition = (dev.condition == null) ? [null] : dev.condition;
                        this.device.conditionNotes = dev.condition_notes;
                        this.device.connectionID = dev.connection_id;
                        this.device.assemblyID = dev.assembly_id;
                        if(this.device.inactive == true){
                            this.formHasError = true;
                            butils.createToast(this, "INACTIVE BFPA!", "This Device is INACTIVE. Must be active to enter test event.", 'danger');
                        }else{
                            if(this.device.modelID == null){
                                this.formHasError = true;
                                this.device.modelFlagged = true;
                                this.makeToast("Previous Device Model is Null", "WARNING: Verify that you are replacing the correct device, it is easy to confuse devices where the device model is unknown.", 'warning');
                            }else{
                                this.fetchModelInformation(this.device.modelID, false);
                            }
                            if(performConnectionUpdate){
                                this.fetchConnectionInformation(this.device.connectionID);
                            }
                        }
                    }else{
                        this.makeToast("Failed To Fetch Device Info", "Device Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Device Info", "Device Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    console.log(error);
                });
        },
        fetchModelInformation(mdlID, setReplacementModel){
            if(mdlID == null){
                makeToast("Unable To Enter BFPA Test", "Device Does Not Have A Valid Model", 'danger');
                return;
            }
            instance.get(process.env.VUE_APP_API_BASE_URL + '/device_models/models/' + mdlID + '.json')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var mdl = response.data.result.records[0];
                        if(setReplacementModel){
                            this.repValues.manufacturer = mdl.manufacturer;
                            this.repValues.type = mdl.type;
                            this.repValues.model = mdl.model;
                            if(this.repValues.type != null && !this.nonTestableDeviceTypes.includes(this.repValues.type)){
                                this.newDeviceRequiresSerial = true;
                            }else{
                                this.newDeviceRequiresSerial = false;
                            }
                        }else{
                            this.device.fetchedModel = true;
                            this.device.manufacturer = mdl.manufacturer;
                            this.device.type = mdl.type;
                            this.device.model = mdl.model;
                        }
                    }else{
                        this.makeToast("Failed To Fetch Device Model Information", "Device Model Info Could Not Be Retrieved Or Was Not Retrieved, Verify That This Device Has A Valid Model", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Device Model Information", "Device Model Info Could Not Be Retrieved Or Was Not Retrieved, Verify That This Device Has A Valid Model", 'danger');
                    console.log(error);
                });
        },
        fetchConnectionInformation(conID){
            this.connection.fetched = false;
            instance.get(process.env.VUE_APP_API_BASE_URL + '/connections/' + conID + '.json?dref=true')
                .then(async (response) => {
                    if(response.data.result.records.length == 1){
                        var con = response.data.result.records[0];
                        this.connection.id = con.connection_id;
                        this.connection.fetched = true;
                        this.connection.location_id = con.location_id;
                        this.connection.utility_account_number = con.utility_account_number;
                        this.connection.utility = con.utility;
                        this.connection.meter_number = con.meter_number;
                        this.connection.route = con.route;
                        this.connection.service_type = con.service_type;
                        this.connection.meter_size = con.meter_size;
                        this.connection.amr_number = con.amr_number;
                        // Setup Meter Reading Initial State
                        if(this.connection.meter_number == 'NO METER' || this.connection.meter_number == 'Na'){
                            this.repValues.meterReadingApplicable = false;
                        }
                        console.log("Fetch Connection Completed")
                    }else{
                        this.makeToast("Failed To Fetch Connection Info", "Connection Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    }
                })
                .catch((error) => {
                    this.makeToast("Failed To Fetch Connection Info", "Connection Info Could Not Be Retrieved Or Was Not Retrieved, Check The Link and Attempt Again", 'danger');
                    console.log(error);
                });
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        },
        initBeforeMount(){
            // Fetch Device
            if(this.isReplacement){
                // Then inspecting device id
                console.log(`Replacement Opened Against Device ID: ${this.inspectID}`)
                this.fetchDeviceInformation(this.inspectID, true);
            }else{
                // We are installing, and thus inspecting the connection id
                console.log(`Install Opened For Connection ID: ${this.inspectID}`)
                this.fetchConnectionInformation(this.inspectID);
            }

            this.$store.commit('initForm',{ localFormID: this.localFormID, meta: this.formDef.meta });
        },
        doAllInit(){
            this.initBeforeMount();
        },
        packageRepInstallAndSubmit(){
            var fVal = this.$store.getters.getForm(this.localFormID);
            var serial = null;
            if(fVal.serial != undefined){
                serial = fVal.serial.value
            }
            var replacement = { 
                connection_id: this.connection.id,
                replaces_device: this.device.id,
                new_device: {
                    device_latitude: fVal.gps_latitude.value,
                    device_longitude: fVal.gps_longitude.value,
                    device_model_id: fVal.modelID.value,
                    device_size: fVal.size.value,
                    device_serial: serial,
                    grade_type: fVal.gradeTypeID.value,
                    height_above_grade: fVal.deviceElevation.value,
                    protection_type: fVal.protectionTypeID.value,
                    device_location: fVal.location.value,
                    device_comments: fVal.notes.value,
                    device_gps_accuracy: this.repValues.gps.acc,
                    device_gps_timestamp: this.repValues.gps.timestamp,
                    schedule_code: fVal.scheduleCode.value,
                    special_flag: fVal.specialFlag.value,
                    hazard_level: fVal.hazardLevelID.value,
                    hazard_type: fVal.hazardTypeID.value,
                },
                technician_id: this.technician.id,
                curbstop_condition: fVal.curbstopCondition.value,
                outlet_connection_info: this.repValues.outletConnectionInfo,
                meter_reading: (_.has(fVal, 'meterReading')) ? fVal.meterReading.value : null,
                event_notes: this.repValues.repNotes,
                verified_pressure: this.repValues.verifiedPressure,
                event_tstamp: this.eventDate + ' ' + this.eventTime,
            };

            // console.log(replacement);
            // console.log(JSON.stringify(replacement));
            this.isSubmitting = true;
            // console.log(replacement);
            instance.post(process.env.VUE_APP_API_BASE_URL + '/events/replacement.json', replacement)
                .then((response)=>{
                    this.isSubmitting = false; 
                    this.submittedSuccesfully = true;
                    this.newDeviceID = response.data.result.newDeviceID;
                    this.ownEventID = response.data.result.replacementEventID;
                    this.$bvModal.show('submission-completed')
                })
                .catch((error)=>{
                    console.log("Failed To Submit Replacement")
                    this.isSubmitting = false; 
                    this.submittedSuccesfully = false;
                })
        },
        preworkValid(){
            var x = this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.repInstPreworkFields});
            return x;
        },
        newDevicevalid(){
            var x = this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.repInstDeviceFields});
            return x;
        },
        notesvalid(){
            var x = this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.repInstNotesFields});
            return x;
        }
    },
    watch:{
        '$route': 'doAllInit'
    },
    computed: {
        
        childrenDataNames: function(){
            // Remove Meta From Consideration
            var valid = _.omit(this.$store.getters.getForm(this.localFormID), 'meta');
            // Get Keys
            return _.keys(valid);
        },
        testKitModel: function(){
            var mdl = '';
            if(this.technician.testKit.manufacturer != null){
                mdl += this.technician.testKit.manufacturer + ' ';
            }
            if(this.technician.testKit.model != null){
                mdl += this.technician.testKit.model;
            }
            return mdl
        },
        testKitCalDate: function(){
            var date = null;
            if(this.technician.testKit.calDate != null){
                date = new Date(this.technician.testKit.calDate).toISOString().substr(0,10);
            }
            return date;
        },
        compInitValid: function(){
            return this.$store.getters.getFormValid({parentFormID: this.localFormID, checkKeys: this.initialTestFields});
        },
    },
    mounted(){
        // Setup Technician, Gauge, And Cert Fields
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'tech_name', value: this.technician.first + ' ' + this.technician.last, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_number', value: this.technician.certs.backflow_tester.num, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'cert_date', value: this.technician.certs.backflow_tester.date, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_kit_sn', value: this.technician.testKit.serial, valid: false} );
        this.$store.commit('registerFormValue', {parentFormID: this.localFormID, type: 'formtextregexinput', dataName: 'test_klt_cal_date', value: this.technician.testKit.calDate, valid: false} );
        //var x = this.$store.getters.getEntireFormValid;
    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    beforeMount (){
        console.log(this.actionType)
        if(this.actionType == 'replacement'){
            this.isReplacement = true;
        }else if(this.actionType == 'install'){
            this.isReplacement = false;
        }else{
            this.formHasError = true;
        }
        this.initBeforeMount();
    },
    beforeCreate(){
    },
}
</script>

<style scoped>
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
.blue-bg{
    background-color: #ebf5ff;
    border-radius: 1em;
    border-width: 0.5em;
    padding: 0.5em;
}
.explain-fail{
    font-size: 0.75em;
}
.larger-header{
    font-size: 1.5em;
}
</style>